<template>
  <div class="switch">
    <button class="button">
      <ButtonCTA
        variant="secondary"
        :content="content"
        @click="emit('switch')"
      />
    </button>

    <!--    <ButtonCounter-->
    <!--      variant="secondary"-->
    <!--      :content="{ icon: 'ion:heart-outline', text: '', count: count }"-->
    <!--    />-->
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n();

const props = defineProps<{
  modelValue: boolean;
  count: number;
}>();

const emit = defineEmits(['switch']);

const content = computed(() => {
  return props.modelValue
    ? {
        icon: 'ion:list',
        text: t('list.map.switch.showlist'),
      }
    : {
        icon: 'ion:map',
        text: t('list.map.switch.showmap'),
      };
});
</script>

<style src="./Switch.scss" scoped lang="scss"></style>
